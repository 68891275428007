/* eslint-disable no-console */

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready () {
//       console.log(
//         'App is being served from cache by a service worker.\n' +
//         'For more details, visit https://goo.gl/AFskqB'
//       )
//     },
//     registered () {
//       console.log('Service worker has been registered.')
//     },
//     cached () {
//       console.log('Content has been cached for offline use.')
//     },
//     updatefound () {
//       console.log('New content is downloading.')
//     },
//     updated (registration) {
//       console.log('New content is available; please refresh.')
//       registration.update()
//     },
//     offline () {
//       console.log('No internet connection found. App is running in offline mode.')
//     },
//     error (error) {
//       console.error('Error during service worker registration:', error)
//     }
//   })
// }


/* eslint-disable no-console */
//@ts-ignore



import { register } from 'register-service-worker'


let appHidden = true;

//Listen for Document Visibility Changes
document.addEventListener('appVisibilityChange', function (event) {
  //@ts-ignore
  appHidden = event.detail;
  
})

// let isPendingUpdate = false;
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      
    },
    registered() {
      
    },
    cached() {
      
    },
    updatefound() {


      document.dispatchEvent(
        new CustomEvent('swUpdateFound', { detail: "" })
      )
    },
    updated(registration) {
      //If App is not visible, show Notification on New Data
      if (appHidden) {
        const notificationOptions = {
          body: "An Update is available for VClass Admin",
          icon: "https://api.tataafricasafaris.com/destinations/ihamba.png",
        };

        registration.showNotification("New Update Available",
          notificationOptions);
      }

      document.dispatchEvent(
        new CustomEvent('swUpdated')
      )
    },
    offline() {
      
    },
    error(error) { // eslint-disable-line no-unused-vars
   
}
})
}
