<template>
  <div class="bg-light mt-5 pt-5 pb-5">
    <div class="container-fluid text-center">
      <span style="font-weight: 300">
        <small>© 2024 All Rights reserved | Tata Africa Safaris</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ["1"],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #e9efec;
  color: grey;
  padding-top: 50px;
  padding-bottom: 50px;
}

footer ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-flex {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex div {
  font-size: 0.9em;
  font-weight: 400;
}

.brand-name {
  margin-left: 50px;
  margin-right: 50px;
}
.col-title {
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
}

.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .nav-flex {
    display: block;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}
</style>

<style>
footer .el-collapse-item__header {
  background-color: #3d290a;
  border-bottom: 1px solid #ebeef5;
  color: #fef9f6;
  text-align: left;
}
footer .el-collapse-item {
  background-color: #3d290a;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__content {
  border: none;
  background-color: #3d290a;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__wrap {
  will-change: height;
  background-color: #3d290a;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
</style>